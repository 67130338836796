(function() {
    'use strict';

    angular.module('adminApp')
        .controller("manageUsersCtrl", manageUsersController)
        .filter('filterRoles', filterRoles);

    manageUsersController.$inject = ["$scope", "$uibModal", "Notification", "aerosAdminApi", "aflAdminUserModal",
        "allRoles", "routesConstant", "instructionsAdmin", "OrganizationRoleService"];

    function manageUsersController($scope, $uibModal, Notification, aerosAdminApi, aflAdminUserModal, allRoles,
                                   routesConstant, instructionsAdmin, OrganizationRoleService) {

        var SEARCHBY = {
            EMAIL: "email",
            SYSTEMROLES: "systemRoles",
            ORGANIZATIONROLES: "organizationRoles"
        };

        angular.extend($scope, {
            afterClearSearchInput: afterClearSearchInput
        });

        clearFilter();


        $scope.$watch("commonReady", function (newVal) {
            if (!newVal) return;

            if (!$scope.allow['editAdminUsers']) return $scope.reject();
            // init
            init();

            function init() {
                //getRoles();
                $scope.roles = allRoles.adminRoles;
                $scope.orgRoles = OrganizationRoleService.getOrganizationRoles();

                clearFilter();

                fetchFilteredUsers();

            }

            $scope.allowEdit = function() {
                // Check to see if the current user can edit system properties.
                return this.$parent.allow["editSystemProperties"]
            }

            $scope.includeRole = function (role) {
                var index = $.inArray(role, $scope.rolesIncluded);

                (index === -1) ? $scope.rolesIncluded.push(role) : $scope.rolesIncluded.splice(index, 1);
            };

            $scope.rolesFilter = function (user) {
                if ($scope.rolesIncluded.length > 0) {
                    if ($.inArray(user.roles.aerosRoles[0].role, $scope.rolesIncluded) < 0)
                        return;
                }
                return user;
            };

            $scope.roleDisplay = function (role) {
                var r = _.find($scope.roles, {id: role});
                if (r) {
                    return r.name;
                }
                return role;
            };

            $scope.filterUsers = function filterUsers(searchBy, email, rolesIncluded, organizationRolesIncluded) {
                if ($scope.filter && $scope.searchBy === SEARCHBY.EMAIL) {
                    $scope.filter.searchIndex = email;
                }
                $scope.email = email;
                $scope.searchBy = searchBy;
                $scope.rolesIncluded = rolesIncluded;
                $scope.organizationRolesIncluded = organizationRolesIncluded;
                $scope.cursor = "";
                $scope.currentPage = 0;
                fetchFilteredUsers();
            };


            $scope.editUser = function (user, action) {
                aflAdminUserModal.open(user, action, $scope.roles, $scope.orgRoles).then(function () {
                    init();
                });
            };

            $scope.getMore = function getMore() {
                if ($scope.cursor && $scope.searchBy === 'email') {
                    return fetchFilteredUsers(true);
                } else {
                    return fetchFilteredUsers(true);
                }
            };

            $scope.isMoreAvailable = function() {
                switch ($scope.searchBy) {
                    case SEARCHBY.EMAIL:
                        return !!$scope.cursor;
                    case SEARCHBY.SYSTEMROLES:
                    case SEARCHBY.ORGANIZATIONROLES:
                        return false;
                    default:
                        return !$scope.isLastPage;
                }
            };

            $scope.$watch('filter.searchIndex', function(newValue, oldValue) {
                if (oldValue != newValue) {
                    if (newValue) {
                        $scope.filterUsers(SEARCHBY.EMAIL, $scope.filter.searchIndex, [], []);
                    } else {
                        $scope.filterUsers('', $scope.filter.searchIndex, [], []);
                    }

                }

            });

            function findInstructionByAction(action) {
                return instructionsAdmin[routesConstant.ADMIN.USERS.stateName]
                    .actions.find(function (element) {
                        return element.action && element.action.toLowerCase() === action.toLowerCase();
                    });
            }

            (function setActions() {

                $scope.actions = {
                    "Add": {
                        label: function label() {
                            return findInstructionByAction("Add").instructions;
                        },
                        icon: function icon() {
                            return findInstructionByAction("Add").icon;
                        },
                        if: function () {
                            return true;
                        },
                        disabled: function () {
                            return false;
                        },
                        action: function () {
                            return $scope.editUser(null, 'create');
                        },
                        placement: function() {
                            return 'left';
                        }
                    },
                    "Edit": {
                        label: function label() {
                            return findInstructionByAction("Edit").instructions;
                        },
                        icon: function icon() {
                            return findInstructionByAction("Edit").icon;
                        },
                        if: function () {
                            return true;
                        },
                        disabled: function () {
                            return false;
                        },
                        action: function (user) {
                            return $scope.editUser(user, 'edit');
                        },
                        placement: function() {
                            return 'left';
                        }
                    },
                    "More": {
                        label: function label() {
                            return findInstructionByAction("More").instructions;
                        },
                        icon: function icon() {
                            return findInstructionByAction("More").icon;
                        },
                        if: function () {
                            return [SEARCHBY.SYSTEMROLES, SEARCHBY.ORGANIZATIONROLES].indexOf($scope.searchBy) < 0;
                        },
                        disabled: function () {
                            return !$scope.isMoreAvailable();
                        },
                        action: function () {
                            if (!this.disabled()){
                                return $scope.getMore();
                            }
                        },
                        placement: function() {
                            return 'top';
                        },
                        buttonText: function() {
                            return "More...";
                        }
                    },
                    "Export Filtered Users as CSV": {
                        label: function label() {
                            return findInstructionByAction("Export Filtered Users as CSV").instructions;
                        },
                        icon: function icon() {
                            return findInstructionByAction("Export Filtered Users as CSV").icon;
                        },
                        if: function () {
                            return true;
                        },
                        disabled: function () {
                            return false;
                        },
                        action: function () {
                            return generateFullCSVFile($scope.adminUsers, 'filteredUsers.csv');
                        },
                        placement: function () {
                            return 'left';
                        }
                    },
                    "Export All Users as CSV (in background)": {
                        label: function label() {
                            return findInstructionByAction("Export All Users as CSV (in background)").instructions;
                        },
                        icon: function icon() {
                            return findInstructionByAction("Export All Users as CSV (in background)").icon;
                        },
                        if: function () {
                            return true;
                        },
                        disabled: function () {
                            return false;
                        },
                        action: function () {
                            return generateFullCSVFileInBackground();
                        },
                        placement: function () {
                            return 'left';
                        }
                    },
                    "Filter": {

                    }
                };

            })();
        });

        function generateFullCSVFile(userList, fileName) {
            var csv = "First Name, Last Name, Email, Organization, User Types (in organization), City, State, Last Login, Date Created\n";

            for(var i = 0; i < userList.length; i++) {
                if( userList[i].OrgInfo.organizations.length == 0 ) {
                    csv += userList[i].firstName + ', ' + userList[i].lastName + ', '
                        + userList[i].email + ',,,,, '
                        + userList[i].lastLoginString + ', '
                        + userList[i].dateCreatedString + '\n';
                } else {
                    for(var j=0; j < userList[i].OrgInfo.organizations.length; j++) {
                        var name = userList[i].OrgInfo.organizations[j].name.replace(/,/g, '');
                        var roles = "";
                        for(var k=0; k < userList[i].OrgInfo.roles[j].length; k++) {
                            roles = roles + userList[i].OrgInfo.roles[j][k];
                            if( k<userList[i].OrgInfo.roles[j].length-1 ){
                                roles = roles + '|';
                            }
                        }
                        roles.replace(/,/g, '');
                        if(userList[i].OrgInfo.contacts[j] != undefined && userList[i].OrgInfo.contacts[j][0] != undefined
                            && userList[i].OrgInfo.contacts[j][0].contactInfo != undefined
                            && userList[i].OrgInfo.contacts[j][0].contactInfo.address != undefined
                            && userList[i].OrgInfo.contacts[j][0].contactInfo.address.city != undefined
                            && userList[i].OrgInfo.contacts[j][0].contactInfo.address.state != undefined ) {
                            csv += userList[i].firstName + ', ' + userList[i].lastName + ', '
                                + userList[i].email + ', ' + name + ', '
                                + roles + ", " + userList[i].OrgInfo.contacts[j][0].contactInfo.address.city + ", "
                                + userList[i].OrgInfo.contacts[j][0].contactInfo.address.state + ", "
                                + userList[i].lastLoginString + ', '
                                + userList[i].dateCreatedString + '\n';
                        } else {
                            csv += userList[i].firstName + ', ' + userList[i].lastName + ', '
                                + userList[i].email + ', ' + name + ', '
                                + roles + ",,, "
                                + userList[i].lastLoginString + ', '
                                + userList[i].dateCreatedString + '\n';
                        }
                    }
                }
            }

            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            hiddenElement.download = fileName;
            hiddenElement.click();
        }

        function runWorker(fn) {
            return new Worker(URL.createObjectURL(new Blob(['('+fn+')()'])));
        }

        function createWorkerFunction (fn,urlReplacement,tokenReplacement) {
            var name = fn.name
            fn = fn.toString()
            fn = fn.replace('url',urlReplacement)
            fn = fn.replace('TOKEN',tokenReplacement)

            return {
                name: name,
                args: fn.substring(fn.indexOf("(") + 1, fn.indexOf(")")),
                body: fn.substring(fn.indexOf("{") + 1, fn.lastIndexOf("}"))
            }
        }

        function workerFunction() {
            fetch("url",
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-User-AerosAuth': 'TOKEN'
                    },
                    body: JSON.stringify({"count": -1, "cursor": ""})
                })
                .then(function(res) {
                    return res.json()
                })
                .then(function(data) {
                    self.postMessage(data)
                })
                .catch(function(err) {
                    self.postMessage("Something went wrong. " + err.message)
                })
        }

        function generateFullCSVFileInBackground() {
            var sessionInfo = JSON.parse(window.localStorage.sessionInfo);
            if (typeof(Worker) !== "undefined") {
                var worker = runWorker(function() {
                    self.onmessage=function(e) {
                        var downloadCsv = new Function(e.data.functionParams[0].body);
                        downloadCsv();
                    }
                })
                worker.onmessage = function(e) {
                    worker.terminate();
                    if( e && e.data && e.data.users ) {
                        generateFullCSVFile(e.data.users, 'allUsers.csv');
                        // turn off spinner
                        document.body.style.cursor = 'default';
                    } else {
                        alert(e);
                    }
                };
                var functionParams = [createWorkerFunction( workerFunction, aerosAdminApi.getAdminUsersFilteredUrl(), sessionInfo.token )];
                worker.postMessage({ functionParams: functionParams });
                // turn on spinner
                document.body.style.cursor = 'wait';
                alert("All users will be downloaded into a CSV file in a background task.  This may take ten minutes.");
            } else {
                console.log("Sorry! No Web Worker support.");
            }
        }

        function clearFilter() {
            $scope.rolesIncluded = [];
            $scope.organizationRolesIncluded = [];
            $scope.searchBy = "";
            $scope.cursor = "";
            $scope.count = 10;
            $scope.currentPage = 0;
            $scope.isLastPage = false;
            $scope.filter = {};
        }

        function fetchFilteredUsers(isMore) {
            aerosAdminApi.getAdminUsersFiltered(getFilterCriteria())
                .success(function (data) {
                    if (isMore) {
                        if ($scope.adminUsers && angular.isArray($scope.adminUsers)
                            && data.users && angular.isArray(data.users)) {
                            $scope.adminUsers = $scope.adminUsers.concat(data.users);
                        }
                    } else {
                        $scope.adminUsers = data.users;
                    }
                    _.each($scope.adminUsers, function (user) {
                        user.searchIndex = user.firstName + " " + user.lastName + " " + user.email;
                    });

                    updateQueryInfoOnSuccess(data);
                })
                .error(function (err) {
                    Notification.error("Something went wrong. " + err.message);
                });
            return;
        }

        function getFilterCriteria() {
            switch ($scope.searchBy) {
                case SEARCHBY.EMAIL:
                    return {
                        "count": $scope.count,
                        "cursor": $scope.cursor,
                        "email": {
                            "startsWith": $scope.filter.searchIndex
                        },
                    };
                case SEARCHBY.SYSTEMROLES:
                    return {
                        "count": $scope.count,
                        "page": $scope.currentPage,
                        "systemRoles": {
                            "roles": $scope.rolesIncluded
                        }
                    };
                case SEARCHBY.ORGANIZATIONROLES:
                    return {
                        "count": $scope.count,
                        "page": $scope.currentPage,
                        "organizationRoles": {
                            "roles": $scope.organizationRolesIncluded
                        }
                    };
                default:
                    return {
                        "count": $scope.count,
                        "cursor": $scope.cursor
                    }
            }
        }

        function updateQueryInfoOnSuccess(data) {
            switch ($scope.searchBy) {
                case SEARCHBY.EMAIL:
                    $scope.cursor = data.cursor;
                    break;
                case SEARCHBY.SYSTEMROLES:
                case SEARCHBY.ORGANIZATIONROLES:
                default:
                    $scope.cursor = data.cursor;
                    $scope.currentPage = $scope.currentPage + 1;
                    $scope.isLastPage = data.users.length !== $scope.count;
                    break;
            }
        }

        function afterClearSearchInput() {
            clearFilter();
        }
    }


    function filterRoles() {
        return function (items, roles) {
            var filtered = [], found = false;

            if (typeof roles !== 'undefined' && roles.length > 0) {
                angular.forEach(items, function (item, index) {
                    var userAerosRoles = [], userGeminiRoles = [];


                    if (item.roles) {
                        if (item.roles.aerosRoles) {
                            item.roles.aerosRoles.forEach(function (v, k) {userAerosRoles.push(v.role)});
                        }
                        if (item.roles.geminiRoles) {
                            item.roles.geminiRoles.forEach(function (v, k) {userGeminiRoles.push(v.role);});
                        }
                    }

                    var int1 = _.intersection(userAerosRoles, roles), int2 = _.intersection(userGeminiRoles, roles);

                    (int1.length === roles.length || int2.length === roles.length) ? found = true : found = false;

                    if (found) {
                        filtered.push(item);
                    }
                });
            } else {
                filtered = items;
            }

            return filtered;
        };
    }

})();
